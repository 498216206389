import React, {useEffect} from 'react';
import {Select} from "antd";


export default function CustomSelect({
                                       children,
                                       open= false,
                                       allowClear =  false,
                                       placeholder = 'Выберите из списка',
                                       name = '',
                                       onChange = null,
                                       className = '',
                                       value
}) {

  return (
    <>
      <Select placeholder={placeholder}
              value={value}
              allowClear={allowClear}
              className={className}
              onChange={onChange}
              getPopupContainer={(triggerNode) =>
                document.getElementById(`area-${name}`)
              }>
        {children}
      </Select>
      <div className="custom-dropdown-container" id={`area-${name}`}></div>
    </>
  )
}