import {Container} from "../../../styles/GlobalStyle";
import {
  MailingFormRight,
  MaillingFormLeft,
  MaillingFormMiddle,
  MaillingFormWrapper,
  MaillingHeader,
  MaillingWrapper,
} from "./Mailing.style";
import React, {useState, useEffect} from "react";
import {Button, Checkbox, Form, Select} from "antd";
import {
  rules,
  REF_TYPE,
  REFERRER_ID,
  SHARE_ID,
  REFERRAL_ID,
  USER,
  FORM_TYPES,
  FORM_EVENT_TYPE, ERROR
} from "../../../helpers/common"
import VacancyModal from "./VacancyModal";
import MessageModal from "../../Layout/MessageModal";
import {fetchReferences, postFetchData, sendFormEvents} from "../../../helpers/utils";
import PositionTooltip from "../PositionTooltip";
import CustomSelect from "../CustomSelect";
import Cookies from "js-cookie";
import gtm from "../../../helpers/gtm";
import {v4 as uuidv4} from 'uuid';
import FormFilling from '../FormFilling';
import CustomEmailSelect from "../CustomEmailSelect";
import api from "../../../api";

let uuid = uuidv4();

const Mailling = ({ isSmall = false,
                    isLightVersion = false,
                    hideSubscribeText = false,
                    addingClassName = '',
                    initialValues = {},
                    vacancyId = null }) => {

  // useLayoutEffect(() => {
  //   setTimeout( () => {
  //     if (path && path.includes('#')) {
  //       const id = path.replace('#', '');
  //       if (id) {
  //         document
  //           .querySelector('#' + id)
  //           .scrollIntoView({ behavior: 'smooth' });
  //       }
  //     }
  //   }, 100)
  // }, [path]);

  const [modalVisible, setModalVisible] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [message, setMessage] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [isFirstChange, setIsFirstChange] = useState(true);
  const [info, setInfo] = useState({ title: null, description: null });
  const [form] = Form.useForm();

  const [fields, setFields] = useState(form.getFieldsValue());
  const [isFormSent, setIsFormSent] = useState(false);

  const [isLightSubscribe, setIsLightSubscribe] = useState(isLightVersion);

  const TOOLTIP_TEXT = 'Для правильного формирования рассылки вам нужно указать текущую отрасль и позицию. Это крайне важно, иначе вам придут ненужные вакансии. Мы же предложим разные варианты карьеры';


  const handleSubscribe = async () => {
    const user = Cookies.get(USER);
    const formValues = form.getFieldsValue();
    //let isLightSubscribe = formValues?.isLightSubscribe;

    const values = formValues?.isLightSubscribe ? {
      email: formValues.email
    } : {
      ...form.getFieldsValue(),
      form_code: "vacancies-subscription",
      from_referrer_id: (Cookies.get(REFERRER_ID) || null),
      referral_id: (Cookies.get(REFERRAL_ID) || null),
      referrer_page_url: window.document.referrer,
      share_id: (Cookies.get(SHARE_ID) || null),
      utm_parameters: user ? JSON.parse(user) : null,
      form_filling_id: uuid
    };

    let result = null;
    let response = null;

    try {
      const data = await api.post(isLightSubscribe ? '/light' : '/auth/subscribe', {}, values)

      setIsFormSent(true);
      //result = data;
      response = {
        title: 'Спасибо!',
        description: isLightSubscribe ?
          ['Вы успешно подписались на рассылку.'] :
          ['Вы успешно подписались на рассылку.', 'На указанный адрес <span class="a2"></span> отправлены доступы в личный кабинет, где вы можете управлять подпиской.'],
        type: 'success',
      };
      setMessage(response);
      form.resetFields();
      setFields(form.getFieldsValue());
      uuid = uuidv4();
      if (gtm.isGtmLoaded() && !formValues?.isLightSubscribe) {
        gtm.pushToEmail(values.email, values.form_code);
      }
    } catch (error) {
      if (error?.code === 1030) {
        response = {
          title: '',
          description: [`Подписка с данным email уже существует. Мы отправили на почтовый адрес <b>${values.email}</b> письмо для входа в Личный кабинет. Если письмо не пришло, напишите, пожалуйста, нам об этом на <a href="mailto:support@facancy.ru" class="support-email">support@facancy.ru</a>`],
          type: ERROR
        }
      } else {
        if (error?.code === 1081) {
          response = {
            title: '',
            description: [`Подписка с данным email уже существует. Если Вы не получаете письмо рассылки, напишите, пожалуйста, нам об этом на <a href="mailto:support@facancy.ru" class="support-email">support@facancy.ru</a>`],
            type: ERROR
          }
        } else {
          response = {
            title: 'Что-то пошло не так, попробуйте ещё раз...',
            type: ERROR
          }
        }
      }
      setMessage(response);
    }

    setMessageVisible(true);
    // postFetchData(isLightSubscribe ? '/light' : '/auth/subscribe', {}, values)
    //   .then( data => {
    //
    //   })
    //   .catch( error => {
    //
    //   })
    //   .finally( end => {
    //
    //   });
  }

  const send = async (formValue) => {
    let formData = new FormData();
    const values = {...formValue, form_code: 'subscription-feedback'};
    for (const name in values) {
      if (values[name]) {
        formData.append(name, values[name]);
      }
    }
    return await postFetchData('/me/questions/subscription', {}, formData, true)
      .then( data => {
        setMessage({
          title: 'Спасибо!',
          description: ['Пока вы ждете ответа, <a href="https://lp.vladimirskaya.com/portfolio" target="_blank">узнайте каким может быть ваше резюме</a>'],
          type: 'success',
        });
        setModalVisible(false);
        setMessageVisible(true);
        return true;
      })
      .catch( error => {
        setMessage({
          title: 'Что-то пошло не так, попробуйте ещё раз...',
          type: 'error'
        });
        setMessageVisible(true);
        return false;
      });
  }


  const handleFormEvents = async () => {
    setIsFormSent(false);
    let values = form.getFieldsValue();
    setFields(values);
    if (isFirstChange) {
      setIsFirstChange(false);
      delete values.policy;
      await sendFormEvents({
        uuid: uuid,
        form_type: FORM_TYPES.SUBSCRIPTION,
        event_type: FORM_EVENT_TYPE.FILLING_FORM,
        values: values,
        vacancy_id: vacancyId
      })
    }

    if (isLightVersion) {
      form.validateFields()
    }
  }

  const onShowTooltip = (data) => {
    setShowInfo(!!data?.description);
    setInfo({ title: data?.title, description: data?.description });
  }

  return (
    <>
      <FormFilling fields={fields} isFormSent={isFormSent} params={{
        uuid: uuid,
        form_type: FORM_TYPES.SUBSCRIPTION,
        event_type: FORM_EVENT_TYPE.FILLING_FORM,
        vacancy_id: vacancyId
      }} />
      <MessageModal {...message}
                    visible={messageVisible}
                    setVisible={setMessageVisible} />
      <VacancyModal sendEmail={send}
                    modalVisible={modalVisible}
                    vacancyId={vacancyId}
                    setModalVisible={setModalVisible} />

      <MaillingWrapper className={`main-mailing ${addingClassName}`} isSmall={isSmall}>
        <Container>
          <div className="main-mailing__wp">
            <>
              {!hideSubscribeText && (
                <MaillingHeader className="main-mailing__top" isSmall={isSmall}>
                  <h3 data-aos="fade" data-aos-duration="700">
                    Как получить рассылку?
                  </h3>

                  <div className="main-mailing__top-item"  data-aos="fade" data-aos-delay="100" data-aos-duration="700">
                    <span>1</span>
                    <div className="p2">Заполнить анкету, описывающую вашу текущую позицию</div>
                  </div>

                  <i data-aos="fade" data-aos-delay="200" data-aos-duration="700">
                    <img className="svg-arrow-rotate" src="/images/home/arrow-orange-icon.svg" />
                  </i>

                  <div className="main-mailing__top-item" data-aos="fade" data-aos-delay="300" data-aos-duration="700">
                    <span>2</span>
                    <div className="p2">Дождаться ближайшего понедельника</div>
                  </div>
                </MaillingHeader>
              )}
            </>


            <MaillingFormWrapper className="main-mailing__form-wp" data-aos="fade" data-aos-duration="700" name="subscription-form" id="subscription-form" isSmall={isSmall}>
              <MaillingFormLeft className="main-mailing__form-left">
                <div className="main-mailing__form-title">
                  <h2>
                    Расскажите, кем вы работаете сейчас?
                    {/*<Tooltip placement="bottom" title={TOOLTIP_TEXT}>*/}
                    {/*  <i>i</i>*/}
                    {/*</Tooltip>*/}
                  </h2>
                </div>

                {/*<div className="p2">*/}
                {/*  И мы подберем для вас вакансию мечты*/}
                {/*</div>*/}

                <blockquote>
                  <p style={{ marginBottom: 10 }}><i>Важно!</i></p>
                  <p>Указывать в анкете параметры вакансий, которые вы хотите увидеть (как это обычно устроено во всех сервисах поиска вакансий) - бессмысленно. Наш алгоритм начнет выбирать вакансии для “такого” человека, а не для вас.</p>
                </blockquote>

                <div className="podz">
                  Откуда вакансии?
                </div>

                <div className="p2">
                  Из LinkedIn, Glassdoor, Телеграме, ВКонтакте, корпоративных сайтов компаний и хантинговых агентств
                </div>

                <div className="main-mailing__mob-off">
                  <div className="podz">Для кого?</div>
                  <div className="p2">Для хороших наёмных менеджеров</div>
                  <div className="podp">Если вы не разобрались, как правильно указать вашу текущую отрасль и функции
                    – <a className="a2 questmod" onClick={ () => setModalVisible(true) }>задайте нам вопрос</a></div>
                </div>
              </MaillingFormLeft>

              <MaillingFormMiddle className="main-mailing__form" isSmall={isSmall}>
                <Form form={form}
                      initialValues={initialValues}
                      layout="vertical"
                      validateTrigger={['submit', 'change']}
                      scrollToFirstError={true}
                      onFinish={handleSubscribe}
                      onFieldsChange={(changedFields, allFields) => {
                        if (isLightVersion) {
                          setIsLightSubscribe(!allFields.some(f => !(f.name.includes('email') || f.name.includes("policy")) && !!f.value))
                        }
                      }}
                      onValuesChange={handleFormEvents}>
                  {/*<Form.Item label="Ваш e-mail"*/}
                  {/*           rules={[{ type: 'email', required: true }]}*/}
                  {/*           name="email"*/}
                  {/*           className="input input-block">*/}
                  {/*    <Input placeholder="Введите данные" />*/}
                  {/*</Form.Item>*/}

                  <UserInfoFormItems
                    isLightVersion={isLightVersion}
                    isLightSubscribe={isLightSubscribe}
                    form={form}
                    showInfo={showInfo} info={info} onShowTooltip={onShowTooltip}
                  />

                  <Form.Item>
                    <Button className="btn" type="primary" htmlType="submit" style={{ marginTop: 0 }}>
                      Отправить <img src="/images/home/arrow-white-icon.svg" width={17}/>
                    </Button>
                  </Form.Item>

                  <div className="main-mailing__mob-on">
                    <div className="podp">Если вы не разобрались, как правильно указать вашу текущую отрасль и функции
                      – <a className="a2 questmod" onClick={ () => setModalVisible(true) }>задайте нам вопрос</a></div>
                  </div>
                </Form>
              </MaillingFormMiddle>

              <MailingFormRight className="main-mailing__form-right">
                <PositionTooltip info={info} isShort={true} customStyle={{ display: showInfo ? 'block' : 'none' }}/>
              </MailingFormRight>
            </MaillingFormWrapper>
          </div>
        </Container>
      </MaillingWrapper>
    </>
  )
}

export default Mailling;


export const UserInfoFormItems = ({
                             isLightVersion, isLightSubscribe, form,
                             showInfo, info, onShowTooltip
                           }) => {

  const [positionValues, setPositionValues] = useState({});

  const _changePositionValue = (value, key) => {
    setPositionValues({
      ...positionValues,
      [key]: value
    })
    // TODO: Если потребуется добавить класс для GTM, то раскомментировать
    // if (process.browser) {
    //   let elements = document.getElementsByClassName("ant-select-selection-item");
    //   for (let item of elements) {
    //     ReactDOM.findDOMNode(item).classList.add("simple-select__selected");
    //   }
    // }
  }

  const [references, setReferences] = useState(null);

  useEffect(() => {
    fetchReferences().then( value => {
      setReferences(value);
    })
  }, []);

  const selectParameter = (id, type) => {
    //setShowInfo(true);
    const currentType = {
      [REF_TYPE.POSITION]: references?.position_levels,
      [REF_TYPE.INDUSTRY]: references?.industries,
      [REF_TYPE.COMPANY_LEVEL]: references?.company_levels,
      [REF_TYPE.FUNCTION]: references?.functions,
    }[type]

    //const currentType = (type === REF_TYPE.POSITION ? references?.position_levels : (type === REF_TYPE.INDUSTRY) ? references?.industries  : []);
    if (currentType?.length === 0) {
      return
    }
    const data = currentType?.filter( it => it.id === id )[0];
    onShowTooltip(data)
  }

  return (
    <>

      <Form.Item label="Ваш e-mail"
                 rules={[{ type: 'email', required: true, validateTrigger: ['onBlur', 'onSubmit'] }]}
                 name="email"
                 className={`input input-block custom-email-select ${!isLightVersion ? '' : 'light-email'}`}>
        <CustomEmailSelect name="email"
                           allowClear={false}
                           containerId="mailing-email"
                           isCustomPopupContainer={true}
                           value={form ? form.getFieldValue('email') : ''}
                           onChange={(value) => {
                             if (form) {
                               form.setFieldsValue({ email: value });
                             }
                           }}
                           placeholder="Введите данные email" />
      </Form.Item>

      <Form.Item label="Уровень вашей компании"
                 rules={isLightSubscribe ? [] : rules}
                 className="input input-block"
                 name="company_level_id">

        <CustomSelect value={positionValues?.company_level_id}
                      allowClear={isLightVersion}
                      name="company_level_id"
                      onChange={id => {
                        _changePositionValue(id, 'company_level_id')
                        selectParameter(id, REF_TYPE.COMPANY_LEVEL)
                      }}>
          {
            references?.company_levels?.map( (it) => {
              return (
                <Select.Option
                  key={it.id}
                  value={it.id}
                  className={`simple-select__item${it.id === positionValues?.company_level_id ? ' is-active' : ''}`}>
                  { it.title }
                </Select.Option>
              )
            })
          }
        </CustomSelect>
      </Form.Item>

      <Form.Item label="Уровень вашей позиции"
                 rules={isLightSubscribe ? [] : rules}
                 className="input input-block"
                 name="position_level_id">
        <CustomSelect value={positionValues?.position_level_id}
                      allowClear={isLightVersion}
                      name="position_level_id"
                      onChange={ (id) => {
                        _changePositionValue(id, 'position_level_id')
                        selectParameter(id, REF_TYPE.POSITION)
                      }}>
          {
            references?.position_levels?.map( (it) => {
              return (
                <Select.Option
                  key={it.id}
                  value={it.id}
                  className={`simple-select__item${it.id === positionValues?.position_level_id ? ' is-active' : ''}`}>
                  { it.title }
                </Select.Option>
              )
            })
          }
        </CustomSelect>
      </Form.Item>

      <Form.Item label="Ваша индустрия"
                 rules={isLightSubscribe ? [] : rules}
                 className="input-block"
                 name="industry_id">
        <CustomSelect value={positionValues?.industry_id}
                      allowClear={isLightVersion}
                      name="industry_id"
                      onChange={ (id) => {
                        _changePositionValue(id, 'industry_id')
                        selectParameter(id, REF_TYPE.INDUSTRY)
                      }}>
          {
            references?.industries?.map( (it) => {
              return (
                <Select.Option
                  key={it.id}
                  value={it.id}
                  className={`simple-select__item${it.id === positionValues?.industry_id ? ' is-active' : ''}`}>
                  { it.title }
                </Select.Option>
              )
            })
          }
        </CustomSelect>
      </Form.Item>

      <Form.Item label="Ваша функция"
                 rules={isLightSubscribe ? [] : rules}
                 className="input-block"
                 name="function_id">
        <CustomSelect value={positionValues?.function_id}
                      allowClear={isLightVersion}
                      name="function_id"
                      onChange={id => {
                        _changePositionValue(id, 'function_id')
                        selectParameter(id, REF_TYPE.FUNCTION)
                      }}>
          {
            references?.functions?.map( (it) => {
              return (
                <Select.Option
                  key={it.id}
                  value={it.id}
                  className={`simple-select__item${it.id === positionValues?.function_id ? ' is-active' : ''}`}>
                  { it.title }
                </Select.Option>
              )
            })
          }
        </CustomSelect>
      </Form.Item>

      {
        showInfo && (
          <PositionTooltip info={info}/>
        )
      }

      <Form.Item rules={[
        {
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(new Error(' ')),
        },
      ]}
                 className="checkbox"
                 valuePropName="checked"
                 name="policy">
        <Checkbox>
          <p className="accept-policy">Я даю согласие на <a className="a2" href="/uploads/soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a>, <a className="a2" href="/uploads/soglasie_na_poluchenie_novostnoj_i_reklamnoj_rassylki.pdf" target="_blank">получение рекламной рассылки</a> и соглашаюсь с <a className="a2" href="/uploads/polzovatelskoe_soglashenie.pdf" target="_blank">условиями пользовательского соглашения</a></p>
        </Checkbox>
      </Form.Item>
    </>
  )

}