import styled from "styled-components";
import {theme} from "/helpers/theme";
import {Container} from "../../../styles/GlobalStyle";


// Mailing

export const MaillingWrapper = styled.section`
  ${ ({ isSmall }) => isSmall && `background: ${theme.colors.white};`}
  
  &.main-mailing {
    padding-top: 95px;
    padding-bottom: 128px;
    background: ${ ({ isSmall }) => isSmall ? theme.colors.white : '#F5F7FB'} 
  }

  .main-mailing__top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 39px;
    margin-bottom: 97px
  }

  .main-mailing__top-item {
    display: -ms-flexbox;
    display: flex;
    max-width: 375px
  }

  .main-mailing__top-item span {
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 24px;
    color: #EF6957;
    display: inline-block;
    margin-right: 16px;
    position: relative;
    top: 6px
  }

  .main-mailing__top i {
    padding-top: 12px
  }

  .main-mailing__top .p2 {
    font-weight: 500
  }

  .main-mailing__top h3 {
    margin-right: 11px
  }

  .main-mailing__form {
    width: 40%;
    max-width: 495px
  }

  .main-mailing__form-left {
    max-width: 429px
  }

  .main-mailing__form-left blockquote {
    margin-top: 50px;
    margin-bottom: 48px
  }

  .main-mailing__form-left .podz {
    line-height: 22px;
    margin-bottom: 16px
  }

  .main-mailing__form-left .podz+.p2 {
    margin-bottom: 25px;
    color: #8A8A89
  }

  .main-mailing__form-left .podp {
    line-height: 18px;
    margin-top: 28px;
    color: #8A8A89
  }

  .main-mailing__form-left .podp .a2 {
    font-size: 14px
  }



  .main-mailing__form-title {
    margin-bottom: 22px
  }

  .main-mailing__form-title i {
    font-style: normal;
    font-size: 21px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #F5F7FB;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    right: -10px;
    top: -3px;
    cursor: pointer
  }

  .main-mailing__form-title i:hover .main-mailing__form-title-info {
    visibility: visible;
    opacity: 1
  }

  .main-mailing__form-title-info {
    position: absolute;
    top: 43px;
    left: -271px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0,0,0,.16);
    width: 473px;
    padding: 24px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s,visibility .3s
  }

  .main-mailing__form-title-info:before {
    content: "";
    position: absolute;
    top: -6px;
    right: 181px;
    width: 16px;
    height: 16px;
    background: #fff;
    transform: rotate(45deg);
    z-index: 1
  }

  .main-mailing__form-wp {
    background: ${ ({ isSmall }) => isSmall ?  `${theme.colors.gray}` : '#fff' } ;
    padding: 80px 60px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-shadow: 0 67px 138px rgba(0,0,0,.0281146),0 4.45557px 9.17714px rgba(0,0,0,.0718854)
  }

  .main-mailing__form .btn {
    margin-top: 36px;
    height: 64px;
    width: 238px;
    font-size: 20px;
    line-height: 24px;
    padding-left: 35px
  }

  .main-mailing__form .btn svg {
    fill: #fff;
    margin-left: 14px;
    margin-top: 1px
  }


  .main-mailing__to-top .simple-select__drop {
    top: auto;
    bottom: 100%
  }

  .main-mailing__mob-on {
    margin-top: 24px
  }

  .main-mailing__mob-on .podp {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px
  }

  .main-mailing__mob-on a {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit
  }

  @media (min-width: 761px) {
    .main-mailing__mob-on {
      display:none
    }
  }


  @media (max-width: 1440px) {

    .main-mailing__top {
      padding-left: 0;
      padding-right: 0;
      -ms-flex-align: center;
      align-items: center
    }

    .main-mailing__top i {
      padding-top: 5px
    }

    .main-mailing__top-item {
      max-width: 285px;
      -ms-flex-align: center;
      align-items: center
    }

    .main-mailing__top-item span {
      top: 0
    }

    .main-mailing__form {
      margin-left: 20px
    }

    .main-mailing__form-left {
      width: 35%
    }

    .main-mailing__form-wp {
      padding: 60px 30px
    }

    .main-mailing__form .btn {
      height: 56px
    }
  }

  @media (max-width: 1140px) {
    .main-mailing__top-item {
      max-width: 260px
    }

    .main-mailing__form {
      width: 58%;
      max-width: 520px
    }

    .main-mailing__form-left {
      width: 39%
    }

    .main-mailing__form-left blockquote {
      margin-top: 30px;
      margin-bottom: 30px
    }
  }

  @media (max-width: 990px) {
    &.main-mailing {
      padding-top: 40px
    }

    .main-mailing__form-left .podp .a2 {
      font-size: 10px
    }

    .main-mailing__form .btn {
      height: 48px;
      font-size: 14px;
      line-height: 16px;
      margin-top: 28px
    }

    .main-mailing__form-title-info {
      font-size: 12px;
      line-height: 18px;
      padding: 17px 16px 20px;
      width: 280px;
      top: 32px;
      left: -235px
    }

    .main-mailing__form-title-info:before {
      right: 26px
    }

    .main-mailing__top {
      display: block;
      margin-bottom: 40px
    }

    .main-mailing__top h3 {
      margin-bottom: 24px
    }

    .main-mailing__top-item {
      margin-bottom: 24px;
      max-width: 450px
    }

    .main-mailing__top-item:last-of-type {
      margin-bottom: 0
    }

    .main-mailing__top-item span {
      font-size: 48px;
      line-height: 24px
    }

    .main-mailing__top-item .p2 {
      font-size: 14px;
      line-height: 20px
    }

    .main-mailing__top i {
      padding: 0;
      margin-bottom: 33px;
      display: block
    }

    .main-mailing__top i svg {
      transform: rotate(90deg)
    }
  }

  @media (max-width: 760px) {
    .main-mailing__mob-off {
      display: none
    }

    .main-mailing__form {
      margin-left: 0;
      width: 100%
    }

    .main-mailing__form-left {
      width: 100%;
      max-width: 560px
    }

    .main-mailing__form-wp {
      display: block
    }

    .main-mailing__form-title i {
      width: 26px;
      height: 26px;
      top: 0
    }
  }

  @media (max-width: 575px) {

    &.main-mailing {
      padding-top: 27px;
      padding-bottom: 36px
    }

    .main-mailing__top {
      margin-bottom: 52px
    }

    .main-mailing__top h3 {
      margin-bottom: 26px
    }

    .main-mailing__top span {
      min-width: 35px;
      margin-right: 7px
    }

    .main-mailing__top-item {
      margin-bottom: 29px
    }

    .main-mailing__top i {
      margin-bottom: 28px
    }

    .main-mailing__top i svg {
      margin-left: 5px
    }

    .main-mailing__form-wp {
      padding: 28px 12px
    }

    .main-mailing__form-title {
      margin-bottom: 10px;
      max-width: 265px
    }

    .main-mailing__form-title i {
      width: 20px;
      height: 20px;
      font-size: 14px;
      line-height: 28px;
      right: -4px;
      top: -2px
    }

    .main-mailing__form-left .p2 {
      line-height: 20px
    }

    .main-mailing__form-left blockquote {
      margin-top: 22px;
      margin-bottom: 23px
    }

    .main-mailing__form-left .podz {
      margin-bottom: 6px
    }

    .main-mailing__form-left .podz+.p2 {
      line-height: 18px;
      margin-bottom: 31px
    }

    .main-mailing__form .default-checkbox {
      margin-left: 0;
      margin-top: -6px
    }

    .main-mailing__form .btn {
      width: 256px;
      margin-top: 15px;
      padding-left: 24px
    }
  }


  .main-mailing__only-email-form {}
  @media (max-width: 760px) {
    .main-mailing__only-email-form {
      margin-top: 25px; }}
  @media (max-width: 575px) {
    .main-mailing__only-email-form {
      margin-top: 31px; }}
  

  &.main-mailing.registration-mailling {
    padding-top: 45px;
  }

  @media (max-width: 990px) {
    .svg-arrow-rotate {
      transform: rotate(90deg);
    }
  }

  @media (max-width: 575px) {
    .svg-arrow-rotate {
      margin-left: 5px;
    }
  }


`;

export const MaillingHeader = styled.div`
`;

export const MaillingFormWrapper = styled.div`
  
`;

export const MaillingFormLeft = styled.div`
`;

export const MaillingFormMiddle = styled.div`
  .light-email label.ant-form-item-required:before {
    content: '*';
    margin-right: 6px;
  }

  
  .btn img {
    margin-left: 14px;
    margin-top: 1px;
  }

  .ant-row.ant-form-item.ant-form-item-with-help.checkbox {
    margin-top: 24px;
  }
   
  input, textarea, .ant-select-selector {
    border: 1px solid ${ ({ isSmall }) => isSmall ? theme.colors.white : '#8A8A89' } !important;
  }

  .ant-select-selection-search-input {
    margin-top: -1px!important;
  }
  
  .show-search-input .ant-select-selection-search-input {
    background-color: white!important;
    height: 60px!important;
  }

  .ant-select-clear {
    right: 23px;
    font-size: 14px;
    top: 50%;
  }

  @media (min-width: 990px) {
    .ant-select-selection-search {
      padding-left: 19px;
      margin-top: -1px;
    }
  }
    
  @media (max-width: 990px) {
    .ant-select-clear {
      right: 11px;
      font-size: 14px;
      top: 50%;
    }
    
    .ant-select-selection-search {
      padding-left: 1px;
      height: 37px!important;
    }
    .show-search-input .ant-select-selection-search-input {
      height: 37px!important;
      margin-top: 1px!important;
    }
    
    .ant-row.ant-form-item.ant-form-item-with-help.checkbox {
      margin-top: 16px;
    }
  }
`;

export const MailingFormRight = styled.div`
  background-image: url('/images/home/form-area.png');

  &.main-mailing__form-right {
    max-width: 306px;
    max-height: 626px;
    width: 30%;
    margin-top: 39px;
    padding: 40px 32px;
    margin-left: 15px
  }

  &.main-mailing__form-right {
    background-image: url(/images/home/form-area.png);
    background-size: cover;
    background-position: 50%
  }
  

  &.lk__position_tooltip {
    &.main-mailing__form-right {
      margin-left: 88px;
      margin-top: 95px;
      max-width: 291px;
      max-height: 475px;
      //background: #ffffff;
      position: relative;
    }

    &.main-mailing__form-right:before {
      content: "";
      display: block;
      position: absolute;
      width: 42px;
      height: 42px;
      background-image: url(/images/home/form-area.png);
      transform: rotate(45deg);
      top: 289px;
      left: -21px
    }
    

    @media (min-width: 1140px) {
      &.main-mailing__form-right {
        margin-top: 439px;
        
        &.lk__parametr-cur-pos {
          margin-top: 490px;
          //margin-top: 210px;
        }
      }

      &.main-mailing__form-right.show-subindustry,
      &.main-mailing__form-right.show-subfunction {
        max-height: 598px;
      }

      &.main-mailing__form-right.show-subindustry.show-subfunction {
        max-height: 719px;
      }

      &.main-mailing__form-right.lk__parametr-position-tooltips-company_level:before {
        top: 18px;
      }

      &.main-mailing__form-right.lk__parametr-position-tooltips-position:before {
        //margin-top: 374px;
        top: 156px;
      }

      &.main-mailing__form-right.lk__parametr-position-tooltips-industry:before {
        //margin-top: 374px;
        top: 290px;
      }

      &.main-mailing__form-right.lk__parametr-position-tooltips-subindustry:before {
        //margin-top: 374px;
        top: 410px;
      }

      &.main-mailing__form-right.lk__parametr-position-tooltips-function:before {
        //margin-top: 374px;
        top: 410px;
      }

      &.main-mailing__form-right.lk__parametr-position-tooltips-function:before {
        //margin-top: 374px;
        top: 410px;
      }

      &.main-mailing__form-right.show-subindustry.lk__parametr-position-tooltips-function:before {
        //margin-top: 374px;
        top: 546px;
      }

      &.main-mailing__form-right.lk__parametr-position-tooltips-subfunction:before {
        top: 546px;
      }

      &.main-mailing__form-right.show-subindustry.lk__parametr-position-tooltips-subfunction:before {
        top: 664px;
      }
      
      &.main-mailing__form-right .posit-triag:before {
        top:146px
      }
    }
  }

  @media (max-width: 1140px) {
    &.main-mailing__form-right {
      display: none;
    }
  }
`;
