import React, { useState } from "react";
import {CustomModal} from "../CustomModal";
import VacancyForm from "../VacancyForm/VacancyForm";

const VacancyModal = ({ sendEmail, modalVisible, setModalVisible, vacancyId = null }) => {

  return (
    <CustomModal
      centered
      visible={modalVisible}
      onOk={() => setModalVisible(false)}
      onCancel={() => setModalVisible(false)}
      className="quest-modal sm-modal-contant ant-modal-extended"
    >
      <div className="modal-close" onClick={ () => setModalVisible(false) }/>
      <div className="quest-modal__wp">
        <VacancyForm
          vacancyId={vacancyId}
          customSendMail={sendEmail}
          messageVisible={modalVisible}
          setMessageVisible={setModalVisible}/>
      </div>
    </CustomModal>
  )
}

export default VacancyModal;
