import React from 'react';
import styled from "styled-components";

export default function PositionTooltip({ info, isShort = false, customStyle = {} }) {
  if (isShort) {
    return (
      <PositionTooltipWrapper className="main-mailing__prompt" style={customStyle}>
        <div className="main-mailing__prompt-wp">
          <h5>{ info?.title } это:</h5>
          <p>{ info?.description }</p>
        </div>
      </PositionTooltipWrapper>
    )
  } else {
    return (
      <PositionTooltipMobWrapper className="main-mailing__form-mob-info">
        <PositionTooltipWrapper className="main-mailing__prompt">
          <div className="main-mailing__prompt-wp">
            <h5>{ info?.title } это:</h5>
            <p style={{ fontSize: 12 }}>{ info?.description }</p>
          </div>
        </PositionTooltipWrapper>
      </PositionTooltipMobWrapper>
    )
  }
}

const PositionTooltipMobWrapper = styled.div`
  background-image: url(/images/home/form-area.png);
  background-size: cover;
  background-position: 50%;
  display: none;
  padding: 20px 12px;
  margin-top: 16px;
  

  @media (max-width: 1140px) {
    display: block;
    padding: 0;
    margin-top: 17px;
    margin-bottom: 17px;
    
    .main-mailing__prompt-wp {
      max-width: 670px;
      padding: 20px 12px;
      min-height: 211px
    }

    .main-mailing__prompt p {
      margin-top: 8px
    }
  }

  @media (max-width: 990px) {
    .main-mailing__prompt-wp {
      max-width: 100%;
    }
  }

  @media (max-width: 760px) {
    .main-mailing__prompt-wp {
      max-width: 100%;
    }
  }
`

const PositionTooltipWrapper = styled.div`
  .main-mailing__prompt p {
    opacity: .8;
    margin-top: 20px
  }

  @media (min-width: 1140px) {
    .main-mailing__prompt p {
      margin-top: 8px
    }
  }

`